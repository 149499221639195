.footer {
  background-color: rgb(5, 32, 66);
  padding: 60px 0px;
}

.footer > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  color: white;
  gap: 20px;
}

.footer > div div > h2 {
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
  :after {
    content: "";
    width: 50px;
    height: 2px;
    background-color: red;
    left: 0;
    bottom: -10px;
    position: absolute;
  }
}

.footer > div div > h2 > span {
  color: red;
}

.footer .content svg {
  margin: 0 20px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  fill: white;
  border-radius: 5px;
  border: 2px solid white;
  padding: 6px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
}

.footer .content p {
  margin: 10px 0;
}

.footer .content ul {
  list-style: circle;
  margin-left: 20px;
}

@media (max-width: 1250px) {
  .footer > div {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
  }
}

@media (max-width: 800px) {
  .footer > div {
    grid-template-columns: 1fr;
  }
}