.gallery-container {
  /* Prevent vertical gaps */
  line-height: 0;
  -webkit-column-count: 3;
  -webkit-column-gap: 0px;
  -moz-column-count: 3;
  -moz-column-gap: 5px;
  column-count: 3;
  column-gap: 5px;
  margin: 50px 0;
}

.thumbnail {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
  margin: 2.5px 0;
}

.thumbnail:hover {
  opacity: 0.7;
  cursor: pointer;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 800px) {
  .gallery-container {
    /* Prevent vertical gaps */
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
