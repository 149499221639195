.popular {
  margin: 100px 0;
}
.sliderContainer .item {
  width: 280px;
}

.sliderContainer .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popular h1 {
  text-transform: uppercase;
  text-align: center;
  color: rgb(4, 13, 22);
}
.popular h1 span {
  color: red;
}

.popular p {
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 1250px) {
  .sliderContainer .item {
    width: 200px;
  }
}
