.ourStory {
  display: grid;
  align-items: center;
  grid-template-columns: 650px auto;
  margin: 100px 0;
  gap: 25px;
}

.ourStory .leftSide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.ourStory .leftSide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.152);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.ourStory .rightSide h3 {
  font-size: 35px;
  margin-bottom: 5px;
}
.ourStory .rightSide h3 span {
  color: red;
}
.ourStory .rightSide p {
  font-size: 16px;
}

.ourStory .rightSide h1 {
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (max-width: 1250px) {
  .ourStory {
    grid-template-columns: 350px auto;
  }
  .ourStory .leftSide {
    grid-template-columns: 1fr;
    grid-template-rows: 200px 200px;
  }
}
@media (max-width: 800px) {
  .ourStory {
    grid-template-columns: 1fr;
  }
}
