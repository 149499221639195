.ourReviewsHeader > h1 {
  text-transform: uppercase;
  text-align: center;
  color: rgb(4, 13, 22);
}
.ourReviewsHeader > h1 span {
  color: red;
}
.ourReviewsHeader > p {
  text-align: center;
  margin-bottom: 30px;
}

.ourReviews {
  display: grid;
  grid-template-columns: 250px auto;
  gap: 20px;
  justify-content: center;
}

.shopDetails {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.263);
  padding: 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  text-align: center;
  margin: 10px 0;
  margin-bottom: 47px;
  height: calc(100% - 40px);
}
.loader {
  display: grid;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
}
.shopDetails img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  margin: auto;
  display: flex;
}

.shopDetails h1 {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: rgb(118, 44, 44);
}

.shopDetails .ratingOfShop {
  display: grid;
  align-items: center;
  text-align: center;
}

.ratingOfShop svg {
  width: 35px;
  height: 35px;
}
.shopDetails p > span {
  color: rgb(251, 142, 40);
}
.ratingOfShop h3 {
  color: rgb(251, 142, 40);
}

.shopDetails button {
  padding: 10px 20px;
  display: flex;
  text-align: center;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  border-radius: 20px;
  border: none;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.883), rgba(255, 0, 0, 0.86));
  color: white;
  cursor: pointer;
}

.shopDetails button svg {
  margin-left: 8px;
}

.reviews {
  width: 100%;
  overflow: hidden;
  padding: 10px;
}

.reviews .review {
  display: grid;
  grid-template-rows: 200px auto;
  height: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.155);
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.reviewTop {
  text-align: center;
}
.reviewTop img {
  width: 100px;
  height: 100px;
}
.ratingReviewTop {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  margin: 5px 0px;
}

.ratingReviewTop svg {
  width: 25px;
  height: 25px;
}

.reviewText {
  height: 100%;
  overflow-y: scroll;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.reviewText::-webkit-scrollbar {
  width: 2px;
}

.reviewText::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.reviewText::-webkit-scrollbar-thumb {
  background-color: rgb(255, 0, 0);
}

.noReviewText {
  text-align: center;
  margin-top: 30px;
  color: gray;
}

.noReviewText svg {
  width: 60px;
  height: 60px;
  color: white;
  background: red;
  padding: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

@media (max-width: 1250px) {
  .ourReviews {
    grid-template-columns: 1fr;
  }
  .reviews {
    padding-bottom: 30px;
  }
  .shopDetails {
    width: 100%;
  }
}
