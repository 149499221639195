.aboutContent {
  text-align: center;
  margin: 50px 0px;
}

.aboutContent p {
  margin: 20px 0;
  font-weight: 300;
}
.aboutContent img {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
}

@media (max-width: 800px) {
  .aboutContent img {
    width: 100%;
  }
}
