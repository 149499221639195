.contactComponent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 60px 0;
}

.sendMessage input,
.sendMessage textarea {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 10px;
  outline: none;
  border-radius: 10px;
  border: 2px solid darkred;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  &:active {
    transform: scale(0.99);
    -webkit-transform: scale(0.99);
    -moz-transform: scale(0.99);
    -ms-transform: scale(0.99);
    -o-transform: scale(0.99);
  }
}

.sendMessage textarea {
  width: 100%;
  height: 160px;
}

.sendMessage button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  color: white;
  background: linear-gradient(50deg, red, black);
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.133);
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  &:active {
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
  }
}

.information h2 {
  font-size: 20px;
  color: red;
  margin: 7px 0;
}

.directions {
  display: flex;
  gap: 5px;
  margin-top: 20px;
  align-items: center;
}

.directions svg {
  width: 30px;
  height: 30px;
  color: red;
}

.location {
  background-color: rgb(187, 187, 187);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.175);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.location iframe {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}


@media (max-width: 1250px) {
  .contactComponent {
    grid-template-columns: 1fr 1fr; /* Two columns for screens up to 1250px */
    grid-template-rows: auto auto 1fr; /* Three rows */
  }

  .sendMessage,
  .information {
    grid-column: span 2; /* Make these sections span two columns */
  }

  .location {
    grid-column: span 2; /* Make the location section span two columns */
  }
  
  .location iframe {
    width: 100%; /* Make the iframe width 100% */
  }
}
