.heroSection {
  background-image: url(../../assets/menu-hero.jpg);
  width: 100%;
  min-height: 645px;
  position: relative;
  z-index: 11;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroSection::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  z-index: -1111;
}

.heroSection .heroContent {
  max-width: 800px;
  margin: auto;
  text-align: center;
  color: white;
  margin-top: 120px;
}

.heroSection .heroContent h1 {
  font-size: 50px;
  font-weight: 400;
}

.heroSection .heroContent h2 {
  font-size: 30px;
  color: white;
}

.heroSection .heroContent h2 > span {
  font-style: italic;
  font-weight: bold;
  color: red;
}

.heroSection .heroContent button {
  padding: 10px 40px;
  margin-top: 20px;
  color: white;
  background: linear-gradient(50deg, red, black);
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.133);
  border: none;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.heroSection .heroContent button:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.415);
}

@media (max-width: 800px) {
  .heroSection .heroContent {
    max-width: 450px;
  }
  .heroSection .heroContent h1 {
    font-size: 30px;
  }
  .heroSection .heroContent p {
    font-size: 13px;
    font-weight: 200;
  }
}
