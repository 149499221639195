.nav {
  display: flex;
  justify-content: space-between;
  padding: 10px 100px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.52);
  color: white;
}

.nav .logo > img {
  width: 125px;
}
.nav .links > ul {
  list-style: none;
  display: flex;
}

.nav .links > ul > a {
  padding: 0 20px;
  font-size: 20px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  position: relative;
}

.nav .links > ul > a::after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: red;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -8px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.nav .links > ul > a:hover::after {
  width: 70px;
}

.nav .links > ul > a.active {
  background: linear-gradient(306deg, red, #000000c9);
  padding-bottom: 5px;
  border-radius: 10px 0px 10px 0px;
}

.menuIcon {
  display: none;
  width: 45px;
  height: 45px;
  cursor: pointer;
}

@media (max-width: 1250px) {
  .nav {
    padding: 10px 50px;
  }
}

@media (max-width: 800px) {
  .menuIcon {
    display: block;
  }
  .nav .links {
    display: none;
  }
  .nav .links.active {
    display: block;
    position: absolute;
    top: 97px;
    background-color: rgba(0, 0, 0, 0.934);
    width: 100%;
    left: 0;
    padding: 20px;
    z-index: 9999;
  }
  .nav .links > ul {
    display: grid;
    text-align: center;
    gap: 20px;
  }
  .nav .links > ul > a.active {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-border-radius: 10px;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 0 20px;
  }
  .nav {
    padding: 10px 10px;
  }
}
