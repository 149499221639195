.ListOfItem {
  margin-bottom: 50px;
}
.menuTitle {
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;
}
.menuTitle span {
  color: red;
}
.menuDescription {
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: gray;
  font-weight: 100;
  font-size: 14px;
}

.typeContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

.menuCategory h2 {
  text-transform: uppercase;
  font-weight: 300;
  cursor: pointer;
}

.menuCategory h2.activeType {
  color: red;
  border-bottom: 1px solid red;
}

.menuItem {
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: 10px;
}
.itemPrice,
.detailsItem {
  flex: 1 0 auto;
  max-width: 70%;
}

.doted {
  flex: 0 1 auto;
  &::before {
    display: block;
    overflow: hidden;
    content: ".............................................................................................................................................................................................................................................................................................................................................................................................................................................................................";
    height: 2em;
    padding: 0 6px;
    word-break: break-word;
    float: right;
  }
}

@media (max-width: 1250px) {
  .typeContainer {
    gap: 10px;
  }
  .menuCategory h2 {
    font-size: 20px;
  }
}
