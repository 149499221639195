@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
ul {
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1200px;
  margin: auto;
}
span.swiper-pagination-bullet {
  width: 30px;
  height: 10px;
  border-radius: 5px;
  background: red;
}

@media (max-width: 1250px) {
  .container {
    max-width: 700px;
  }
}

@media (max-width: 800px) {
  .container {
    max-width: 450px;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 0 20px;
  }
}
