.tableBook {
  text-align: center;
  margin: 100px 0px;
}

.tableBook h1 {
  color: rgb(6, 24, 41);
  text-transform: uppercase;
  margin: 10px 0;
}

.tableBook h1 span {
  color: red;
}

.tableBook button {
  padding: 10px 40px;
  margin-top: 20px;
  color: white;
  background: linear-gradient(50deg, red, black);
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.133);
  border: none;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  text-transform: uppercase;
}
